<template>
  <div ref="subsidies" class="subsidies">
    <div class="top">
      <img src="https://cdn.cailu88.com/jingxuanshi/client_subsidies_2.png"/>
    </div>
    <div class="bottom">
      <div class="image">
        <img src="https://cdn.cailu88.com/jingxuanshi/client_subsidies_button@2x.png"
             v-if="channel !== 'weixin' && channel !== 'zfb'" @click="isAuth"/>
      </div>
      <div class="content">
        <div style="padding: 20px 28px">
          <p class="copy">{{ content }}</p>
          <van-button v-if="!authStatus" type="primary" @click="isAuth('copy')">复制口令</van-button>
          <van-button v-else @click="copyText($event)">复制口令</van-button>
          <p class="text">
            <span>复制文案</span>
            <van-icon name="arrow"/>
            <span>好友下单</span>
            <van-icon name="arrow"/>
            <span>你赚佣金</span>
          </p>
        </div>
      </div>
    </div>
    <Loading :click="click"></Loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { Button, Icon, Toast } from 'vant'
import Loading from '../../components/loading'
import baseUrl from '@/utils/baseUrl'
import clipboard from '@/utils/copy'

const api = require('../../utils/api').api

Vue.use(Button)
Vue.use(Icon)
Vue.use(Toast)

export default {
  name: 'subsidies',
  data () {
    return {
      authStatus: false,
      token: '',
      version: '',
      channel: '',
      url: '',
      copyContent: '',
      content: '2020【聚划算，双11预售high不停】！【点击复制生成专属口令】，进入【T❥B】抢购:/',
      click: true,
      id: '20150318020001879',
    }
  },
  components: {
    Loading,
  },
  created () {
    this.token = this.$route.query.token
    this.version = this.$route.query.version
    this.channel = this.$route.query.channel
  },
  mounted () {
    this.$axios(api.auth, {}).then(res => {
      if (res.data.data.authStatus == 0) {
        this.authStatus = false
      } else {
        this.authStatus = true
        this.click = false
        this.$axios.post(api.strategyUrl, {
          tbActivityMaterialId: this.id
        }).then(res => {
          this.url = res.data.data.spreadUrl
          this.copyContent = res.data.data.shareContent
          this.click = true
        }).catch(err => {
          this.click = true
        })
      }
    })
  },
  methods: {

    //复制
    copyText (event) {
      this.content = this.copyContent
      Toast.success({
        duration: 500, // 持续展示 toast
        message: '复制成功'
      })
      clipboard(this.copyContent, event)
    },

    // 进入会场
    jump () {
      let paramObj = {
        version: this.version,
        url: this.url,
        backUrl: `${baseUrl.pageUrl}/coupon`
      }
      this.$h5AppLink('version', paramObj)
    },

    // 授权
    isAuth (type) {
      if (!this.click) {
        return false
      }
      if (this.url) {
        setTimeout(() => {
          this.jump()
        })
        return
      }
      this.$axios(api.auth, {}).then(res => {
        if (res.data.data.authStatus == 0) {
          let event = 'openTbAuth'
          let param = {
            authUrl: res.data.data.authUrl,
            backUrl: `${baseUrl.pageUrl}/coupon`
          }
          this.$h5AppLink(event, param)
        } else {
          this.authStatus = true
          this.handleGetUrl(type)
        }
      }).catch(err => {
        this.isLogin()
      })
    },

    // 获取url
    handleGetUrl (type) {
      this.$axios.post(api.strategyUrl, {
        tbActivityMaterialId: this.id
      }).then(res => {
        this.url = res.data.data.spreadUrl
        this.copyContent = res.data.data.shareContent
        this.click = true
        if (type) {
          return false
        }
        setTimeout(() => {
          this.jump()
        }, 100)
      }).catch(err => {
        Toast.fail(err.data.message)
        this.click = true
        console.log(err)
      })
    },

    // 登陆
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/coupon`
      }
      this.$h5AppLink(event, param)
    },

  },
}
</script>
<style lang="less" scoped>
.subsidies {
  min-height: 100vh;
  background: #82009A;

  .top {
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .bottom {
    width: 100%;
    text-align: center;

    .image {
      margin-bottom: 36px;

      img {
        width: 275px;
      }
    }

    .content {
      margin: 0 auto;
      width: 340px;
      background: #FFFFFF;
      border-radius: 12px;

      div {
        display: flex;
        flex-direction: column;
      }

      p {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }

      .copy {
        margin-bottom: 20px;
        line-height: 21px;
        text-align: left;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 3; //想显示多少行就给多大值
        //-webkit-box-orient: vertical;
      }

      .van-button {
        margin: 0 auto;
        width: 250px;
        height: 50px;
        background: #FB3337;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        border-radius: 25px;
        border: none;
      }

      .text {
        margin-top: 15px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #FB3337;
        line-height: 20px;

        .van-icon {
          margin: 0 12px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
